import React, { useContext, useMemo } from 'react';
import useAxiosRequest from '../../../hooks/useAxiosRequest';
import { ConfiguratorContext } from '../context';
import { getProduct } from '../../../api';
import { Alert, Radio, Form, Button, Row, Col, Card, Divider } from 'antd';
import SpinLoader from '../../../components/UI/SpinLoader';
import { setQueryStringValuesWithoutNavigation } from '../../../utils/router';
import PageHeader from '../../../components/UI/PageHeader';
import { UndoOutlined } from "@ant-design/icons";
import ProductCard from '../../../components/Product/ProductCard';
import { useTranslation } from 'react-i18next';

const WITHOUT_IMAGE_ACCESSORIES = ['without_handle', 'push_pull'];

const AccessoriesForm = ({ variants, onFinish, t }) => {

    const dataSource = useMemo(() => {
        return variants
            .map((variant) => {

                return {
                    id: variant.id,
                    name: variant.name,
                    lock: variant.attributes.find((attr) => attr.name === 'lock_areo')?.value || null,
                    technical_specification_url: variant.technical_specification_url
                }

            })
            .sort((a, b) => a.lock.localeCompare(b.lock))
    }, [variants]);

    return (

        <Form
            layout="vertical"
            onFinish={onFinish}
        >
            <Form.Item name="variant_id"
                rules={[{ required: true, message: t('configurator.select_accessory_error') }]}
            >
                <Radio.Group>
                    <Row gutter={[16, 16]}>
                        {dataSource.sort((a, b) => {
                            if (WITHOUT_IMAGE_ACCESSORIES.includes(a.lock) && !WITHOUT_IMAGE_ACCESSORIES.includes(b.lock)) return 1;
                            if (!WITHOUT_IMAGE_ACCESSORIES.includes(a.lock) && WITHOUT_IMAGE_ACCESSORIES.includes(b.lock)) return -1;
                            return 0;
                        }).map((item) => (
                            <Col xs={24} sm={12} lg={24} xl={12}>
                                <Radio
                                    key={item.id}
                                    value={item.id}>
                                    <div className="flex">
                                        {!WITHOUT_IMAGE_ACCESSORIES.includes(item.lock) && (
                                            <img alt={t(`products.${item.lock}`)} style={{ width: 100 }} src={`/build/images/configurator/areo-accessories/${item.lock}.webp`} />
                                        )}
                                        <span style={{
                                            alignSelf: 'center',
                                        }}>
                                            {t(`products.${item.lock}`)}
                                        </span>
                                    </div>
                                </Radio>
                            </Col>
                        ))}

                    </Row>
                </Radio.Group>
            </Form.Item>
            <Divider
            />
            <Form.Item>
                <Button type="primary" htmlType="submit" >
                    {t('configurator.select')}
                </Button>
            </Form.Item>
        </Form>
    )
}

const AccessoriesStep = () => {

    const { t } = useTranslation();

    const { state, selectVariant, resetState } = useContext(ConfiguratorContext)

    const { data, error, loading } = useAxiosRequest(() =>
        getProduct(state.choice)
    )

    const onFinish = (values) => {

        const variant = data?.data.find((variant) => variant.id === values.variant_id);

        selectVariant(variant);

        setQueryStringValuesWithoutNavigation({
            variant_id: values.variant_id,
            step: 3
        })
    }

    const userChoice = state.choice;

    if (!userChoice.product) {
        resetState()
        return null;
    }

    if (loading) return <SpinLoader />

    if (error) {       
        return <Alert message={t('configurator.error')} description={error} type="error" showIcon />
    }

    return (
        <div>
            <PageHeader
                title={t('configurator.select_accessories')}
                subTitle={t('configurator.select_accessories_subtitle')}
                extra={
                    <Button icon={<UndoOutlined />} type="default" onClick={() => resetState()}>
                        {t('configurator.cancel')}
                    </Button>
                }
            />


            <Row gutter={[24, 24]}>

                <Col xs={24} lg={12}>
                    <ProductCard
                        product={userChoice.product}
                        userChoice={userChoice}                        
                    />
                </Col>

                <Col xs={24} lg={12}>

                    <Card title={t('configurator.accessories')}>
                        <AccessoriesForm variants={data?.data} onFinish={onFinish} t={t} />
                    </Card>

                </Col>
            </Row>
        </div>
    );
}

export default AccessoriesStep;